:root {
  --c-menu-color-active: #ebebeb;
  --c-menu-color-active-hover: #d5d5d5;

  --c-menu-width: 340px;
  --c-menu-height-offset: 144px;
  --c-menu-account-item-height-mobile: calc(
    8px * 11
  ); /* Also in customThemeTokens/numbers.ts */

  --c-mobile-menu-width: 370px; /* Also in customThemeTokens/numbers.ts */
}

[data-color-mode='dark'] {
  --c-menu-color-active: #292929;
  --c-menu-color-active-hover: #707070;
}
