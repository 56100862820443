.wrapper {
  padding: var(--v-space-16) var(--v-space-24) var(--v-space-32);
  background-color: var(--v-color-background-secondary);

  @media (--v-from-md) {
    padding: var(--v-space-24) var(--v-space-48) var(--v-space-48);
  }
}

.link {
  display: block;
  padding: var(--v-space-8) var(--v-space-8);
  margin: var(--v-space-8) var(--v-space-8);
  color: var(--v-color-foreground-primary);

  &:hover {
    color: var(--v-color-foreground-accent-blue);
  }
}
